import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useClickAway } from "react-use";

// Components
import { DropdownIcon } from "../icons/dropdown-icon";

// Utils
import { convertToKebabCase } from "../utils/convert-to-kebab";

const MaterialsDropdown = styled.div`
	position: relative;
	width: 100px;

	& .toggle-share-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		width: 100px;
		margin: 0;

		&:hover {
			color: inherit;
		}
	}

	& .share-buttons-container {
		top: 30px;
		right: 0;

		position: absolute;

		width: 200px;
		background: white;

		border-radius: 0;

		background-color: hsl(0, 0%, 100%);
		box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

		z-index: 1;
		box-sizing: border-box;

		& ol {
			padding: 0;
			margin: 0;

			& li {
				& button {
					width: 100%;
					padding: 8px 12px;

					text-align: left;

					&:hover {
						background-color: #f5f5f5;
						color: inherit;
					}
				}
			}
		}
	}
`;

export const MaterialsLibraryDropdown = ({
	options,
	setActiveMaterialsFilter,
}) => {
	const [isShareVisible, setIsShareVisible] = useState(false);

	const ref = useRef(null);
	useClickAway(ref, () => {
		setIsShareVisible(false);
	});

	const materialDropdownOptions = options.map((content, index) => (
		<li
			key={`dropdown_id_${convertToKebabCase(
				content.primary.section_title.text
			)}`}
		>
			<button
				onClick={() => {
					setActiveMaterialsFilter(
						convertToKebabCase(content.primary.section_title.text)
					);
					setIsShareVisible(false);
				}}
			>
				{content.primary.section_title.text}
			</button>
		</li>
	));

	return (
		<MaterialsDropdown ref={ref}>
			<button
				className="toggle-share-container"
				onClick={() => setIsShareVisible(!isShareVisible)}
			>
				<span>Materials</span>
				<DropdownIcon isOpen={isShareVisible} />
			</button>

			{isShareVisible && (
				<div className="share-buttons-container">
					<ol>
						<li>
							<button
								onClick={() => {
									setActiveMaterialsFilter("All");
									setIsShareVisible(false);
								}}
							>
								All
							</button>
						</li>
						{materialDropdownOptions}
					</ol>
				</div>
			)}
		</MaterialsDropdown>
	);
};
