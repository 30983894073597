import React, { useEffect, useContext, useState } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import Helmet from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../../../components/context/window-width";
import StoreContext from "../../../components/context/store";
import { FooterColor } from "../../../components/context/footer-color";

// Components
import Layout from "../../../components/account/Layout";
import { AccountNavigation } from "../../../components/account/account-navigation";
import { MobileAccountNavigation } from "../../../components/account/mobile-account-navigation";
import { AspectRatioImageContainer } from "../../../components/containers/aspect-ratio-image-container";
import { MaterialsLibraryDropdown } from "../../../components/trade/materials-library-dropdown";

import {
	Page,
	AccountContainer,
	ContentContainer,
	TwoColumnGrid,
} from "../../../components/trade/trade-components";

// Utils
import { convertToKebabCase } from "../../../components/utils/convert-to-kebab";

// Styles
import { tertiary } from "../../../components/utils/colors";

// Queries
const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			lastName
			tags
		}
	}
`;

const MaterialsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	max-width: 770px;
`;

const MaterialSection = styled.div`
	margin: 0 0 70px 0;

	max-width: 770px;
	display: ${(props) => props.display};
`;

const MaterialSwatch = styled.div`
	& .material-title {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		margin: 10px 0 0 0;
	}

	& img {
		opacity: 1;
		transition: 250ms opacity ease;
	}

	&:hover img {
		opacity: 0.5;
	}

	& p {
		margin: 0;
	}
`;

const MaterialsLibrary = ({ data }) => {
	const windowWidth = useContext(WindowWidthContext);
	const { customerAccessToken } = useContext(StoreContext);
	const [activeMaterialsFilter, setActiveMaterialsFilter] = useState("All");

	const [footerColor, setFooterColor] = useContext(FooterColor);
	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const materialsLibraryData = data.prismicMaterialsLibrary.data;

	const allMaterials = materialsLibraryData.body.map((content, index) => {
		const materials = content.items
			.filter((material) => material.material_image.fluid !== null)
			.map((material, index) => (
				<MaterialSwatch key={`material_swatch_${index}_${content.id}`}>
					<a
						href={material.material_download.url}
						target="_blank"
						rel="noreferrer"
						download
					>
						<AspectRatioImageContainer image={null} padding={`66.667`}>
							<img
								srcSet={material.material_image.fluid.srcSetWebp}
								src={material.material_image.fluid.srcWebp}
								alt={material.material_title.text}
								loading="lazy"
							/>
						</AspectRatioImageContainer>
						<div className="material-title">
							<div
								className=""
								dangerouslySetInnerHTML={{
									__html: material.material_title.html,
								}}
							/>
							<span>↓</span>
						</div>
						<div>
							<div
								dangerouslySetInnerHTML={{
									__html: material.material_text.html,
								}}
							/>
						</div>
					</a>
				</MaterialSwatch>
			));
		return (
			<MaterialSection
				key={`materials_library_${content.id}`}
				id={convertToKebabCase(content.primary.section_title.text)}
				display={
					activeMaterialsFilter === "All" ||
					activeMaterialsFilter ===
						convertToKebabCase(content.primary.section_title.text)
						? `block`
						: `none`
				}
			>
				<div
					className="section-title caps"
					dangerouslySetInnerHTML={{
						__html: content.primary.section_title.html,
					}}
				/>
				<TwoColumnGrid>{materials}</TwoColumnGrid>
			</MaterialSection>
		);
	});

	return (
		<Layout>
			{customerAccessToken !== null && (
				<Query
					query={CUSTOMER_INFO}
					variables={{
						customerAccessToken: customerAccessToken.accessToken,
					}}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">Fetching</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}
						if (error) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">
												Error loading page. Please try refreshing.
											</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}

						if (error) {
						}

						const isTrade =
							data.customer.tags !== undefined
								? data.customer.tags.includes("trade") || data.customer.tags.includes("Trade")
								: false;

						if (isTrade === false && typeof window !== "undefined") {
							return <>{navigate(`/account`)}</>;
						} else {
							return (
								<Page>
									<Helmet title={`Materials Library | Christian Watson`} />

									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>

									<Media
										queries={{
											medium: "(max-width: 1024px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1024,
										}}
										render={() => (
											<MobileAccountNavigation position={`relative`} top={0} />
										)}
									/>

									<AccountContainer>
										<ContentContainer>
											<div className="section">
												<MaterialsContainer>
													<div
														className="caps"
														dangerouslySetInnerHTML={{
															__html: materialsLibraryData.title.html,
														}}
													/>
													<MaterialsLibraryDropdown
														options={materialsLibraryData.body}
														setActiveMaterialsFilter={setActiveMaterialsFilter}
													/>
												</MaterialsContainer>
												<div
													className="text-column"
													dangerouslySetInnerHTML={{
														__html: materialsLibraryData.text.html,
													}}
												/>
											</div>
											{allMaterials}
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}
					}}
				</Query>
			)}
		</Layout>
	);
};

export const query = graphql`
	{
		prismicMaterialsLibrary {
			data {
				title {
					html
				}
				text {
					html
				}
				body {
					... on PrismicMaterialsLibraryBodyMaterialSection {
						id
						primary {
							section_title {
								html
								text
							}
						}
						items {
							material_image {
								alt
								fluid(imgixParams: { w: 700, fit: "clip" }) {
									srcSetWebp
									srcWebp
								}
							}
							material_title {
								html
								text
							}
							material_text {
								html
								text
							}
							material_download {
								url
							}
						}
					}
				}
			}
		}
	}
`;

export default MaterialsLibrary;
